import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"

const TalkWithUs = ({ data, location }) => {
  const intl = useIntl();
  const { title, maps } = data.site.siteMetadata;

  return (
    <Layout location={location} siteTitle={title} fullscreen>
      <Seo title={intl.formatMessage({id: "navlinks.talkWithUs"})} lang={intl.locale} />
      <iframe className="cover-slider" title={intl.formatMessage({ id: "talkWithUs.axlGroups" })} id="axlGroups"
              src={maps.axlGroups} />
    </Layout>
  )
}

export default TalkWithUs

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        maps {
          axlGroups
        }   
      }
    }
  }
`
